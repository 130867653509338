import React, { useState } from "react";
import { Form, Button, Alert, Spinner, Modal } from "react-bootstrap";
import { db } from "./firebase";
import { addDoc, doc, setDoc } from "firebase/firestore";
import "../register/Register.css";
import Footer from "../Footer";
import Navbar from "../Navbar";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "I am new user",
  });

  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const addUser = async (e) => {
    e.preventDefault();
    setShowSuccessModal(false);
    // Check if all required fields are filled
    if (!formData.name || !formData.email || !formData.phone) {
      setShowError(true);
      console.error("All fields are required");
      return;
    }

    setShowError(false);
    setLoading(true);

    try {
      const userDocRef = doc(db, "new_users", formData.email);
      await setDoc(userDocRef, {
        ...formData,
      });

      console.log("Document written with ID: ", userDocRef.id);

      // Clear the form after successful submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "I am new user",
      });

      console.log("User details stored successfully!");

      // Show the success modal
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setLoading(false);
    }
  };

  console.log("showSuccessModal:", showSuccessModal); // Check the value of showSuccessModal

  return (
    <div>
      <Navbar />
      <div className="container">
        <h2 className="title-hme">Build new strategy</h2>
        <ul className="detsils">
          <li>Need help coding your strategy?</li>
          <li>worried about your coder stealing your system?</li>
          <li>Difficulties in conveying your ideas to the developer?</li>
          <li>
            {" "}
            NO MORE !!! Algoturtle is here to help. We specialise in making
            custom strategies for our users, complete backtesting and strategy
            configuration using our Turtle AI.
          </li>
          <li>
            Every user is provided with a spcialist, who guides them through the
            entire strategy building process. your strategy remains your
            inellectual property.
          </li>
          <li>
            *tick wise data *faster execution speeds *reliable coding practices
          </li>
        </ul>
        <Form>
          <div className="card-register">
            <Form.Group controlId="name">
              <Form.Control
                className="register-input"
                type="text"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Control
                className="register-input"
                type="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="phone">
              <Form.Control
                className="register-input"
                type="tel"
                placeholder="Your Phone Number"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="message">
              <Form.Control
                as="textarea"
                className="register-input"
                rows={3}
                placeholder="Your Message ( I am new user )"
                onChange={handleChange}
                required
              />
            </Form.Group>

            {showError && (
              <Alert variant="danger" className="error-alert">
                Please fill in all the required fields.
              </Alert>
            )}
            {showSuccessModal && (
              <p>
                Thank you for reaching out! Your details have been successfully
                submitted. We will get in touch with you as soon as possible.
              </p>
            )}
            <Button
              type="submit"
              className="submit-button"
              onClick={addUser}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </Form>

        {/* Success Modal */}
      </div>
    </div>
  );
};

export default Register;
