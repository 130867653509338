import React from "react";
import "./Footer.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";

function Footer() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (
    <div className="footer-container">
      {/* <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Experience the power of innovation with India's pioneering AI-based
          algorithmic trading platform
        </p>
        <p className="footer-subscription-text">
          Transforming Trading with Artificial Intelligence and our AlgoTurtle:
          Where Precision Meets Profitability.
        </p>
      </section> */}
      <div class="footer-links">
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2>About Us</h2>
            <Link to="/">How it works</Link>
            <Link to="/">Testimonials</Link>
            <Link to="/">Careers</Link>
            <Link to="/">Investors</Link>
            <Link to="/TermsOfService">Terms of Service</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
          <div class="footer-link-items">
            <h2>Contact Us</h2>
            <Link to="/qrcard">Contact</Link>
            <Link to="/customer-support">Customer support</Link>

            <Link to="/cancellation">Payment Cancellation & Refund</Link>
            <Link to="/">Destinations</Link>
            <Link to="/">Sponsorships</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2>Videos</h2>
            <Link to="/">Demo Video</Link>
            <Link to="/">Tutorials</Link>
            {/*  <Link to="/">Agency</Link>
            <Link to="/">Influencer</Link> */}
          </div>
          {/* <div class="footer-link-items">
            <h2>Social Media</h2>
            <Link to="/">Instagram</Link>
            <Link to="/">Facebook</Link>
            <Link to="/">Youtube</Link>
            <Link to="/">Twitter</Link>
          </div> */}
        </div>
      </div>

      <section class="social-media">
        {/* name bottom left */}
        <div class="social-media-wrap">
          {/* <div class="footer-logo">
            <Link to="/" className="social-logo">
              AlgoTurtle
            </Link>
          </div> */}

          {/* allways current date */}
          <small className="website-rights">
            AlgoTurtle © {getCurrentYear()}
          </small>

          {/* this bootom right icons section for social media  */}
          <div class="social-icons">
            <a
              className="social-icon-link facebook"
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <i className="fab fa-facebook-f" />
            </a>

            <a
              className="social-icon-link instagram"
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram" />
            </a>

            <a
              className="social-icon-link youtube"
              href="https://www.youtube.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Youtube"
            >
              <i className="fab fa-youtube" />
            </a>

            <a
              className="social-icon-link twitter"
              href="https://www.twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <i className="fab fa-twitter" />
            </a>

            <a
              className="social-icon-link twitter"
              href="https://www.linkedin.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <i className="fab fa-linkedin" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
