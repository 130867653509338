import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import logo from "../images/logo.jpg";
import menuIcon from "../images/menuIcon.png"; // Replace with your menu icon image

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    setButton(window.innerWidth > 960);
  };

  useEffect(() => {
    showButton();
    window.addEventListener("resize", showButton);
    return () => window.removeEventListener("resize", showButton);
  }, []);

  // Update active item based on the current route
  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setActiveItem(0);
        break;
      case "/dashboard":
        setActiveItem(1);
        break;
      case "/algoTurtle-ai":
        setActiveItem(2);
        break;
      case "/pricing":
        setActiveItem(3);
        break;
      case "/blogs":
        setActiveItem(4);
        break;
      case "/about-us":
        setActiveItem(5);
        break;
      default:
        setActiveItem(null);
    }
  }, [location.pathname]);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <a href="/">
            <img src={logo} alt="Logo" className="logo" />
          </a>
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <span style={{ color: "#2D3192" }}>algo</span>
            <span style={{ color: "#008000" }}>Turtle</span>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <img src={menuIcon} alt="Menu" className="menu-img" />
          </div>
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              to="/"
              className={`nav-links ${activeItem === 0 ? "active" : ""}`}
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/dashboard"
              className={`nav-links ${activeItem === 1 ? "active" : ""}`}
            >
              Dashboard
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/algoTurtle-ai"
              className={`nav-links ${activeItem === 2 ? "active" : ""}`}
            >
              Ai Graph
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/pricing"
              className={`nav-links ${activeItem === 3 ? "active" : ""}`}
            >
              Pricing
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/blogs"
              className={`nav-links ${activeItem === 4 ? "active" : ""}`}
            >
              Blogs
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/about-us"
              className={`nav-links ${activeItem === 5 ? "active" : ""}`}
            >
              About us
            </Link>
          </li>
          <li>
            <Link
              to="/register"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Build strategy
            </Link>
          </li>
        </ul>
        {button && (
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
          >
            <Link to="/register" className="links" onClick={closeMobileMenu}>
              Build strategy
            </Link>
          </Button>
        )}
      </nav>
    </>
  );
}

export default Navbar;
