import React from "react";
import Navbar from "../Navbar";
import Cancellation from "./Cancellation";

const TermsOfService = () => {
  const currentDate = new Date().toLocaleDateString();

  return (
    <div>
      <Navbar />
      <div
        style={{
          fontFamily: "Poppins, sans-serif",
          padding: "10px 20px",
          maxWidth: "100%",
          margin: "5% 10%",
          background: "#fff",
          borderRadius: "10px",
          lineHeight: "1.6",
          color: "#000",
        }}
      >
        <h1 style={{ textAlign: "center", color: "#333" }}>Terms of Service</h1>
        <p style={{ textAlign: "center", fontWeight: "bold" }}>
          Last Updated: {currentDate}
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using <strong>algoTurtle</strong> , you acknowledge
          that you have read, understood, and agree to these terms. If you do
          not agree, please do not use the Platform.
        </p>

        <h2>2. Eligibility</h2>
        <ul>
          <li>
            You must be at least 18 years old and legally capable of entering
            into agreements.
          </li>
          <li>
            You must comply with all applicable laws and regulations in India
            regarding algorithmic trading.
          </li>
        </ul>

        <h2>3. Use of the Platform</h2>
        <ul>
          <li>
            Our Platform provides algorithmic trading solutions, including
            strike price calculations and automated trading in the Indian stock
            market.
          </li>
          <li>
            You are responsible for ensuring that your trading strategies comply
            with SEBI regulations and relevant exchanges.
          </li>
          <li>We do not provide financial, investment, or legal advice.</li>
        </ul>

        <h2>4. Risk Acknowledgment</h2>
        <p>
          Algorithmic trading involves market risks. We do not guarantee profits
          or protection against losses. You acknowledge that trading decisions
          made using our algorithms are at your own risk.
        </p>

        <h2>5. Account Registration</h2>
        <p>
          You must provide accurate and complete information during registration
          and maintain the security of your account credentials.
        </p>

        <h2>6. Fees & Payments</h2>
        <p>
          Some features of the Platform may require payment. Fees, if
          applicable, will be disclosed before usage. We reserve the right to
          change pricing structures with prior notice.
        </p>

        <h2>7. Intellectual Property</h2>
        <p>
          All content, algorithms, and technology on the Platform are our
          exclusive property. You may not copy, distribute, or modify any part
          of the Platform without prior written consent.
        </p>

        <h2>8. Termination</h2>
        <p>
          We may suspend or terminate your access to the Platform if you violate
          these terms or applicable laws. You may terminate your account at any
          time by discontinuing use.
        </p>

        <h2>9. Liability & Indemnification</h2>
        <p>
          We are not liable for any direct or indirect losses arising from your
          use of the Platform. You agree to indemnify us against any claims
          arising from your misuse of the Platform.
        </p>

        <h2>10. Changes to Terms</h2>
        <p>
          We may update these terms from time to time. Continued use of the
          Platform after updates constitutes acceptance of the revised terms.
        </p>

        <h2>11. Governing Law</h2>
        <p>
          These Terms of Service are governed by the laws of India. Any disputes
          will be subject to the jurisdiction of Indian courts.
        </p>

        <h2>12. Contact Us</h2>
        <p>
          If you have any questions regarding these terms, please contact us at
          <a href="/qrcard">
            <strong> Contact Us</strong>
          </a>
          .
        </p>
        <br />
        <h2
          style={{
            color: "#222",
            marginBottom: "15px",
            fontWeight: "700",
          }}
        >
          Payment Cancellation & Refund
        </h2>
        <p>
          If you have any issues with your payment or need a refund, please fill
          out the form below.
        </p>

        <h3>🔄 Refund Process</h3>
        <ul style={{ textAlign: "left", padding: "0 20px" }}>
          <li>Submit a refund request using the form below.</li>
          <li>
            Refunds are processed within <b>5-7 business days</b>.
          </li>
          <li>
            If the payment was made via <b>Stripe</b>, it may take additional
            time to reflect in your account.
          </li>
          <a href="/cancellation">
            <strong>Send Payment Cancellation & Refund Request</strong>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default TermsOfService;
