import React, { useState } from "react";
import Navbar from "../Navbar";

function Cancellation() {
  const [formData, setFormData] = useState({
    email: "",
    reason: "",
  });
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.email || !formData.transactionId || !formData.reason) {
      setResponseMessage("⚠️ Please fill in all fields.");
      return;
    }

    setResponseMessage(
      "✅ Refund request submitted successfully. We will get back to you soon!"
    );
    setFormData({ email: "", transactionId: "", reason: "" });
  };

  return (
    <div>
      <Navbar />
      <div
        style={{
          fontFamily: "Poppins, sans-serif",
          background: "linear-gradient(135deg, #f0f4f8, #d9e2ec)",
          margin: "1%",
          display: "flex",
          justifyContent: "center",
          padding: "0",
          maxHeight: "100vh",
          alignItems: "center",
          width: "100%",
          color: "#000",
        }}
      >
        <div
          style={{
            width: "90%",
            padding: "30px",
            background: "#fff",
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
            borderRadius: "15px",
            textAlign: "left",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <h2
            style={{
              color: "#222",
              marginBottom: "15px",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            Payment Cancellation & Refund
          </h2>
          <p>
            If you have any issues with your payment or need a refund, please
            fill out the form below.
          </p>

          <h3>🔄 Refund Process</h3>
          <ul style={{ textAlign: "left", padding: "0 20px" }}>
            <li>Submit a refund request using the form below.</li>
            <li>
              Refunds are processed within <b>5-7 business days</b>.
            </li>
            <li>
              If the payment was made via <b>Stripe</b>, it may take additional
              time to reflect in your account.
            </li>
          </ul>

          <h3>📩 Request a Refund</h3>
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <label>Email Address:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="Enter your email"
              style={{
                padding: "12px",
                borderRadius: "10px",
                fontSize: "16px",
                border: "1px solid #bbb",
                width: "100%",
              }}
            />

            <label>Reason for Refund:</label>
            <textarea
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              required
              placeholder="Explain your reason for the refund request"
              style={{
                padding: "12px",
                borderRadius: "10px",
                fontSize: "16px",
                border: "1px solid #bbb",
                height: "80px",
                width: "100%",
              }}
            ></textarea>

            <button
              type="submit"
              style={{
                background: "linear-gradient(135deg, #007bff, #0056b3)",
                color: "white",
                border: "none",
                padding: "12px",
                fontSize: "18px",
                borderRadius: "10px",
                cursor: "pointer",
                transition: "0.3s, transform 0.2s",
                width: "100%",
              }}
              onMouseOver={(e) =>
                (e.target.style.transform = "translateY(-3px)")
              }
              onMouseOut={(e) => (e.target.style.transform = "translateY(0)")}
            >
              Submit Request
            </button>
          </form>

          {responseMessage && (
            <p
              style={{
                marginTop: "15px",
                fontWeight: "bold",
                color: "green",
                fontSize: "17px",
              }}
            >
              {responseMessage}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Cancellation;
