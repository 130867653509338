import React, { useEffect, useState, useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  CartesianGrid,
} from "recharts";
import Navbar from "../Navbar";

const CACHE_KEY = "aiChartData";
const MAX_ENTRIES = 10000;

const AI = () => {
  const [data, setData] = useState(() => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    return cachedData ? JSON.parse(cachedData) : [];
  });

  const chartRef = useRef(null);

  useEffect(() => {
    const eventSource = new EventSource(
      "https://notify.algoturtle.com/ai_endpoint/sse"
    );

    eventSource.onopen = () => console.log("✅ SSE connection established.");

    eventSource.onmessage = (event) => {
      console.log("📩 Raw Event Data:", event.data);

      try {
        const parsedData = JSON.parse(event.data);

        let cleanMessage = parsedData.message.replace(/'/g, '"'); // Convert single quotes to double quotes
        cleanMessage = cleanMessage.replace(/\bNaN\b/g, "null");
        cleanMessage = cleanMessage.replace(/\bnan\b/g, "null");

        console.log("🛠️ Cleaned JSON String:", cleanMessage);

        const messageData = JSON.parse(cleanMessage);
        console.log("✅ Parsed Message Data:", messageData);

        const formattedData = {
          timestamp: new Date(messageData.timestamp_ms).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }),
          actual_ltp: messageData.actual_ltp,
          predicted_ltp: messageData.predicted_ltp ?? 0,
        };

        setData((prevData) => {
          const updatedData = [...prevData, formattedData].slice(-MAX_ENTRIES);
          localStorage.setItem(CACHE_KEY, JSON.stringify(updatedData));
          return updatedData;
        });
      } catch (error) {
        console.error("❌ Error parsing SSE data:", error);
      }
    };

    eventSource.onerror = (error) => {
      console.error("❌ SSE error:", error);
      eventSource.close();
    };

    return () => {
      console.log("🚪 Closing SSE connection...");
      eventSource.close();
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#ffff",
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <Navbar />
      <div
        style={{
          width: "90%",
          margin: "auto",
          background: "#ffffff",
          borderRadius: "10px",
        }}
      >
        <h2 style={{ color: "#00000", textAlign: "center" }}>
          Live AI Trading Chart
        </h2>
        <ResponsiveContainer
          width="100%"
          height={400}
          margin="auto"
          ref={chartRef}
        >
          <LineChart data={data}>
            {/* Grid */}
            <CartesianGrid strokeDasharray="3 3" stroke="#444" />

            {/* X-Axis */}
            <XAxis
              dataKey="timestamp"
              stroke="#aaa"
              tick={{ fill: "#000" }}
              tickLine={false}
              axisLine={{ stroke: "#444" }}
            />

            {/* Y-Axis with dynamic domain */}
            <YAxis
              stroke="#aaa"
              tick={{ fill: "#000" }}
              tickLine={false}
              axisLine={{ stroke: "#444" }}
              domain={["auto", "auto"]}
            />

            {/* Tooltip */}
            <Tooltip
              contentStyle={{
                backgroundColor: "#333",
                borderRadius: "5px",
                color: "#fff",
              }}
              itemStyle={{ color: "#fff" }}
            />

            {/* Legend */}
            <Legend wrapperStyle={{ color: "#fff" }} />

            {/* Actual LTP Line */}
            <Line
              type="monotone"
              dataKey="actual_ltp"
              stroke="#2d3191"
              strokeWidth={2.5}
              dot={{ fill: "#ff7300", r: 3 }}
              name="Actual LTP"
            />

            {/* Predicted LTP Line */}
            <Line
              type="monotone"
              dataKey="predicted_ltp"
              stroke="rgb(0, 128, 0)"
              strokeWidth={2.5}
              dot={{ fill: "#00c49f", r: 3 }}
              name="Predicted LTP"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AI;
